import { useEffect, useState, useRef } from 'react'
import styled, { css } from 'styled-components'
import { textLinkWithHover } from '../../styles/textLink'
import Image from '../Image'
import Embed from './Embed'
import Gallery from './Gallery'
import PrintLinks, { usePrintLinks } from '../PrintLinks'

function getVideoParams( block ) {
  if ( block.videoFile?.[ 0 ]?.url ) {
    return {
      videoType: 'file',
      videoUrl: block.videoFile?.[ 0 ]?.url,
    }
  }

  if ( block.videoUrl ) {
    return {
      videoType: 'embed',
      videoUrl: getVideoUrl( block.videoUrl ),
    }
  }

  return {
    videoType: null,
    videoUrl: null,
  }
}

function getVideoUrl( url: string ) {
  const youTubeRegExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
  const youTubeMatch = url.match( youTubeRegExp )

  if ( youTubeMatch && youTubeMatch[ 2 ]?.length === 11 ) {
    return `//www.youtube-nocookie.com/embed/${ youTubeMatch[ 2 ] }`
  }

  const vimeoRegExp = /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_-]+)?/i
  const vimeoMatch = url.match( vimeoRegExp )

  if ( vimeoMatch && vimeoMatch[ 1 ]?.length > 1 ) {
    return `//player.vimeo.com/video/${ vimeoMatch[ 1 ] }?dnt=1`
  }
  
  return null
}

function ArticleContent( { articleContent, elementType = 'div' as 'div' | 'main' } ) {
  const containerRef = useRef( null )
  const [containerWidth, setContainerWidth] = useState( null )
  const { printLinksRef } = usePrintLinks()
  
  useEffect( () => {
    if ( containerRef.current ) {
      setContainerWidth( containerRef.current.offsetWidth )
    }
  }, [] )

  return (
    <StyledArticleContent 
      ref={ containerRef } 
      as={ elementType }
    >
      <div ref={ printLinksRef }>
        { articleContent.map( block => {
          if ( block.typeHandle === 'text' ) {
            return (
              <StyledArticleBlock key={ block.id }>
                <StyledArticleText 
                  dangerouslySetInnerHTML={ { __html: block.text } }
                />
              </StyledArticleBlock>
            )
          }

          if ( block.typeHandle === 'image' ) {
            const image = block.image[ 0 ]

            return (
              <StyledArticleBlock 
                key={ block.id }
                $constrainToViewportHeight
                $aspectRatio={ `${ image.width }/${ image.height }` }
              >
                <Image
                  src={ image.url }  
                  alt={ image.imageAltText }
                  width={ image.width }  
                  height={ image.height }
                  sizes="min( 40rem, 87vw )"
                  unoptimized={ image.animatedGif }
                />
                { block.caption && (
                  <StyledArticleCaption dangerouslySetInnerHTML={ { __html: block.caption } } />
                ) }
              </StyledArticleBlock>
            )
          }
        
          if ( block.typeHandle === 'gallery' ) {
            return (
              <StyledArticleBlock key={ block.id }>
                <Gallery images={ block.images } />
              </StyledArticleBlock>
            )
          }
        
          if ( block.typeHandle === 'video' ) {
            const { videoUrl, videoType } = getVideoParams( block )

            if ( !videoUrl ) {
              return null
            }

            return (
              <StyledArticleBlock key={ block.id }>
                <StyledArticleVideo>
                  { videoType === 'file' && (
                  // eslint-disable-next-line jsx-a11y/media-has-caption
                  (<video controls>
                    <source src={ videoUrl } />Sorry, your browser doesn’t support embedded videos.
                                      </video>)
                  ) }
                  { videoType === 'embed' && (
                  <div>
                    <iframe
                      width="853"
                      height="480"
                      src={ `${ videoUrl }` }
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title="Embedded youtube"
                    />
                  </div>
                  ) }
                </StyledArticleVideo>
                { block.caption && (
                  <StyledArticleCaption dangerouslySetInnerHTML={ { __html: block.caption } } />
                ) }
              </StyledArticleBlock>
            );
          }

          if ( block.typeHandle === 'embed' ) {
            if ( !block.embedUrl ) {
              return null
            }

            return (
              <StyledArticleBlock key={ block.id }>
                <Embed 
                  oEmbed={ block.embedUrl } 
                  unsupportedUrl={ block.unsupportedUrl }
                  containerWidth={ containerWidth }
                  iframeWidth={ block.iframeWidth }
                  iframeHeight={ block.iframeHeight }
                />
                { block.caption && (
                  <StyledArticleCaption dangerouslySetInnerHTML={ { __html: block.caption } } />
                ) }
              </StyledArticleBlock>
            )
          }

          return null
        } ) }
        <PrintLinks />
      </div>
    </StyledArticleContent>
  );
}

export const StyledArticleContent = styled.div`
  line-height: 1.4;

  p {
    margin: 1rem 0;

    &:first-child {
      margin-top: 0;
    }
  }

  h2,
  h3,
  h4 {
    margin-top: 2rem;
    ${ p => p.theme.typo.generalSansSemiBold };

    &:first-child {
      margin-top: 0;
    }
  }

  h2 {
    font-size: ${ p => p.theme.typo.sizes.medium };
  }
  
  h3 {
    font-size: ${ p => p.theme.typo.sizes.normal };
  }
  
  h4 {
    font-size: ${ p => p.theme.typo.sizes.dropped };
  }

  ul {
    padding: 0 0 0 2rem;
    margin: 0 0 1rem 0;
    list-style-type: none;

    ul,
    ol {
      margin: 0;
      padding-top: 0.5rem;
      padding-left: 1rem;
    }

    > li {
      padding-bottom: 0.5rem;
      text-indent: -1rem;

      &:last-child {
        padding-bottom: 0;
      }

      &:before {
        content: '▪ ';
        display: inline-block;
        margin-left: 1rem;
        color: ${ p => p.theme.colors.red };
      }
    }
  }

  ol {
    padding: 0 0 0 2rem;
    margin: 0 0 1rem 0;
    list-style: none;
    counter-reset: ol-counter;

    ol,
    ul {
      margin: 0;
      padding-top: 0.5rem;
      padding-left: 1rem;
    }

    > li {
      padding-bottom: 0.5rem;
      text-indent: -1rem;
      counter-increment: ol-counter;

      &:last-child {
        padding-bottom: 0;
      }

      &:before {
        content: counter( ol-counter ) ". ";
        display: inline-block;
        margin-left: 1rem;
        color: ${ p => p.theme.colors.red };
      }
    }
  }

  blockquote {
    padding: 0 0 0 2rem;
    margin: 1rem 0;
    border-left: 1px solid ${ p => p.theme.colors.red };
  }

  em,
  i {
    font-family: 'Rowan-VariableItalic', serif;
  }

  strong,
  b {
    font-variation-settings: 'wght' 700;
  }

  a {
    color: inherit;
    ${ textLinkWithHover }

    + sup {
      display: none;
      text-decoration: none;

      &:before {
        content: '[';
        color: ${ p => p.theme.colors.red };
      }
      
      &:after {
        content: ']';
        color: ${ p => p.theme.colors.red };
      }
    }
  }

  @media print {
    a[href] + sup {
      display: inline;
    }
  }
`

const StyledArticleBlock = styled.div<{
  $constrainToViewportHeight?: boolean,
  $aspectRatio?: string,
}>`
  margin: 2rem 0;
  
  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  * {
    max-width: 100%;
  }

  ${ p => p.$constrainToViewportHeight && css`
    max-height: 80vh;

    ${ p.$aspectRatio && css`
        aspect-ratio: ${ p.$aspectRatio };
    ` }
  ` }
`

const StyledArticleText = styled.div`
  max-width: 40rem;

  > * {
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const StyledArticleVideo = styled.figure`
  margin: 0;
  
  > video {
    max-width: 100%;
  }

  > div {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    
    > iframe {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute;
    }
  }
`

const StyledArticleCaption = styled.figcaption`
  margin-top: 0.5rem;
  ${ p => p.theme.typo.generalSans };
  font-size: ${ p => p.theme.typo.sizes.dropped };

  > * {
    &:first-child {
      margin-top: 0;
    }
    
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default ArticleContent
